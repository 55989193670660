<template>
  <div class="container">
    <img class="bg" :src="require('../assets/trill-tip-bg.png')" alt="">
    <div class="tips flex-column">
      <div class="tip">请使用抖音APP扫码授权</div>
    </div>
  </div>
</template>
<script>


export default {
  name: "TrillFail",
}
</script>

<style scoped>
.container {
  background: #fff;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 34.93vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.bg {
  width: 125.6vw;
  height: 49.33vw;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tips {
  margin-top: 5.33vw;
}

.tip {
  font-size: 18px;
  color: #666;
  margin-top: 0.53vw;
}
</style>
